
document.addEventListener('DOMContentLoaded', (event) => {
  const searchInput = document.getElementById('searchInput')
  const searchButton = document.getElementById('searchButton')

  // searchButton.addEventListener('click', event => {
  //   event.preventDefault()
  //   const area = searchInput.value

  //   location.href = `/posts/search?area=${area}`
  // })
})
